import { Component } from "react";

class Gallery extends Component {
	state = {
		documentHeight: 0
	};

	componentDidMount() {
		document.title = "Auto Plac - DM Valjevo - Galerija";
	}

	render() {
		return null;
	}
}

export default Gallery;
