import React, { Component } from "react";
import { Header, Grid, Image, Divider } from "semantic-ui-react";
import audi from "../../res/brands/audi.png";
import alfa from "../../res/brands/alfa.png";
import bmw from "../../res/brands/bmw.png";
import citroen from "../../res/brands/citroen.png";
import fiat from "../../res/brands/fiat.png";
import ford from "../../res/brands/ford.png";
import honda from "../../res/brands/honda.png";
import hyundai from "../../res/brands/hyundai.png";
import jeep from "../../res/brands/jeep.png";
import kia from "../../res/brands/kia.png";
import landrover from "../../res/brands/landrover.png";
import mazda from "../../res/brands/mazda.png";
import mercedes from "../../res/brands/mercedes.png";
import mini from "../../res/brands/mini.png";
import nissan from "../../res/brands/nissan.png";
import peugeot from "../../res/brands/peugeot.png";
import renault from "../../res/brands/renault.png";
import suzuki from "../../res/brands/suzuki.png";
import toyota from "../../res/brands/toyota.png";
import volvo from "../../res/brands/volvo.png";
import vw from "../../res/brands/vw.png";

class BrandsSegment extends Component {
	constructor(props) {
		super(props);
		this.state = { customTitle: props.customTitle };
	}
	render() {
		return (
			<Grid centered>
				<Header as="h2" textAlign="center">
					{this.state.customTitle}
				</Header>
				<Divider hidden />
				<Image.Group size="tiny">
					<Image src={audi} alt="Audi polovni automobili - DM Valjevo" />
					<Image src={bmw} alt="BMW polovni automobili - DM Valjevo" />
					<Image src={alfa} alt="Alfa Romeo polovni automobili - DM Valjevo" />
					<Image src={citroen} alt="Citroen polovni automobili - DM Valjevo" />
					<Image src={fiat} alt="Fiat polovni automobili - DM Valjevo" />
					<Image src={ford} alt="Ford polovni automobili - DM Valjevo" />
					<Image src={honda} alt="Honda polovni automobili - DM Valjevo" />
					<Image src={hyundai} alt="Hyundai polovni automobili - DM Valjevo" />
					<Image src={jeep} alt="Jeep polovni automobili - DM Valjevo" />
					<Image src={kia} alt="Kia polovni automobili - DM Valjevo" />
					<Image
						src={landrover}
						alt="Land Rover polovni automobili - DM Valjevo"
					/>
					<Image src={mazda} alt="Mazda polovni automobili - DM Valjevo" />
					<Image
						src={mercedes}
						alt="Mercedes polovni automobili - DM Valjevo"
					/>
					<Image src={mini} alt="Mini polovni automobili - DM Valjevo" />
					<Image src={nissan} alt="Nissan polovni automobili - DM Valjevo" />
					<Image src={peugeot} alt="Peugeot polovni automobili - DM Valjevo" />
					<Image src={renault} alt="Renault polovni automobili - DM Valjevo" />
					<Image src={suzuki} alt="Suzuki polovni automobili - DM Valjevo" />
					<Image src={toyota} alt="Toyota polovni automobili - DM Valjevo" />
					<Image src={volvo} alt="Volvo polovni automobili - DM Valjevo" />
					<Image src={vw} alt="Volkswagen polovni automobili - DM Valjevo" />
				</Image.Group>
			</Grid>
		);
	}
}

export default BrandsSegment;
