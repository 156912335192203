import React, { Component } from "react";
import { Menu, Image, Icon, Sidebar } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import logo from "../../../res/dm-valjevo-logo.svg";

export default class MobileHeader extends Component {
	state = {
		visible: false
	};
	handleShowClick = () => this.setState({ visible: true });
	handleSidebarHide = () => this.setState({ visible: false });

	render() {
		return (
			<div>
				<Menu borderless fixed="top" size="massive">
					<Image as={NavLink} to="/">
						<img
							style={{
								width: "135px",
								height: "45px",
								margin: "8px"
							}}
							src={logo}
							alt="DM Valjevo - Logo"
						/>
					</Image>
					<Menu.Item
						position="right"
						name="meni"
						onClick={this.handleShowClick}
					>
						<Icon name="bars" />
						Meni
					</Menu.Item>
				</Menu>

				<Sidebar
					as={Menu}
					size="massive"
					onHide={this.handleSidebarHide}
					vertical
					direction="right"
					visible={this.state.visible}
					width="wide"
				>
					<Menu.Item
						position="right"
						name="close"
						onClick={this.handleSidebarHide}
					>
						<Icon name="x" />
						Zatvori
					</Menu.Item>
					<Menu.Item as={NavLink} to="/vozila" onClick={this.handleSidebarHide}>
						<Icon name="car" />
						Vozila
					</Menu.Item>
					{/* <Menu.Item
						as={NavLink}
						to="/galerija"
						onClick={this.handleSidebarHide}
					>
					<Icon name="images outline"/>
					Galerija
					</Menu.Item> */}
					<Menu.Item
						as={NavLink}
						to="/kontakt"
						onClick={this.handleSidebarHide}
					>
						<Icon name="call" />
						Kontakt
					</Menu.Item>
					<Menu.Item as={NavLink} to="/o-nama" onClick={this.handleSidebarHide}>
						<Icon name="i" />O nama
					</Menu.Item>
				</Sidebar>
			</div>
		);
	}
}
