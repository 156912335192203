import React, { Component } from "react";
import { Grid, Header, Divider, Container } from "semantic-ui-react";
import MapSegment from "./components/content/map-segment";
import ContactSegment from "./components/content/contact-segment";
import WorkTimeSegment from "./components/content/work-time-segment";
import cover from "./res/cover.jpg";
import MetaTags from "react-meta-tags";

class Contact extends Component {
	render() {
		return (
			<div>
				<MetaTags>
					<title>
						Kontakt | Auto plac - DM Valjevo | Prodaja polovnih automobila
					</title>
					<meta
						name="description"
						content="DM Valjevo - Kontakt - Auto plac u Valjevu od sada i na internetu. Na našem sajtu čeka Vas veliki izbor kvalitetnih, proverenih polovnih automobila iz uvoza."
					/>
					<meta
						property="og:title"
						content="Auto plac - DM Valjevo | Prodaja polovnih automobila"
					/>
				</MetaTags>
				<div
					style={{
						maxHeight: "400px",
						backgroundImage: "url(" + cover + ")",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center",
						paddingTop: 160,
						textAlign: "center",
						paddingBottom: 160
					}}
				>
					<Header
						as="h1"
						textAlign="center"
						size="huge"
						style={{ color: "#fff", textShadow: "1px 1px 1px black" }}
					>
						Kontakt
					</Header>
					<Header
						as="p"
						textAlign="center"
						size="large"
						style={{ color: "#fff", textShadow: "1px 1px 1px black" }}
					>
						Posetite nas ili pozovite.
					</Header>
				</div>
				<Container content>
					<Grid stackable>
						<Grid.Row columns="16" style={{ background: "#fff" }}>
							<Grid.Column
								width="5"
								style={{ paddingLeft: 25, paddingRight: 25 }}
							>
								<Divider hidden />
								<WorkTimeSegment customTitle="Radno vreme" />
								<ContactSegment />
							</Grid.Column>
							<Grid.Column width="11">
								<Divider hidden />
								<MapSegment customTitle="Pronađite nas" />
								<Divider hidden />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		);
	}
}

export default Contact;
