import React, { Component } from "react";
import { Header, Button, Grid } from "semantic-ui-react";

class ContactSegment extends Component {
	constructor(props) {
		super(props);
		this.state = { customTitle: props.customTitle };
	}
	render() {
		return (
			<Grid centered>
				<Header as="h2" textAlign="center">
					{this.state.customTitle}
				</Header>
				<Button.Group fluid>
					<Button
						basic
						color="red"
						as="a"
						href="tel:+381641303742"
						icon="call"
						content="064/130-37-42"
					/>

					<Button
						basic
						color="red"
						as="a"
						href="tel:+381600141010"
						icon="call"
						content="060/014-10-10"
					/>
				</Button.Group>
			</Grid>
		);
	}
}

export default ContactSegment;
