import React, { Component } from "react";
import axios from "axios";
import {
    Container,
    Grid,
    Header,
    Table,
    Label,
    List,
    Divider,
    Segment
} from "semantic-ui-react";
import "react-image-gallery/styles/css/image-gallery.css";
import MetaTags from "react-meta-tags";
import ImageGallery from "react-image-gallery";
import CallUsDetails from "./call-us-details";

class VehicleDetails extends Component {
    constructor(props) {
        super(props);
        this.getAdDetails = this.getAdDetails.bind(this);
    }

    state = {
        payloadData: {
            images: {
                adImages: []
            }
        },
        classifiedId: this.props.match.params.classifiedId,
        loading: false,
        images: [{
            original: ""
        }],
        error: false,
        title: "",
        price: "",
        additional: "",
        general: "",
        condition: "",
        safety: "",
        equipment: "",
        description: "",
        phones: "",
        isFullScreen: false
    };

    componentWillMount() {
        this.getAdDetails(this.state.classifiedId);
    }

    _onScreenChange(fullScreenElement) {
        this.setState({
            isFullScreen: fullScreenElement
        });
    }

    getAdDetails(id) {
        this.setState({ loading: true }, () => {
            axios
                .get(`https://api.polovniautomobili.com/json/v5/getAdDetails/${id}`)
                .then((res) => {
                    this.setState({
                        payloadData: res.data.payloadData,
                        error: res.data.error
                    });
                    var images = [];

                    var title,
                        general,
                        additional,
                        condition,
                        equipment,
                        safety,
                        phones,
                        description = "";

                    if (this.state.payloadData.title === undefined) {
                        title = ( <
                            Header size = 'huge'
                            as = 'h1' >
                            Naslov nije učitan - Greška pri učitavanju <
                            /Header>
                        );
                    } else {
                        title = ( <
                            Header size = 'huge'
                            as = 'h1' > { this.state.payloadData.title } <
                            /Header>
                        );
                    }

                    if (this.state.payloadData.adImages.images === undefined) {
                        images = [{
                            original: "https://react.semantic-ui.com/images/wireframe/image.png",
                            caption: `${this.state.payloadData.title}`,
                            originalAlt: `${this.state.payloadData.title}`
                        }];
                    } else {
                        images = this.state.payloadData.adImages.images.map((img) => ({
                            original: `${res.data.payloadData.adImages.basePath}${img}-800x600.jpg`,
                            caption: `${this.state.payloadData.title}`,
                            originalAlt: `${this.state.payloadData.title}`
                        }));
                    }

                    if (this.state.payloadData.general === undefined) {
                        general = "";
                    } else {
                        general = ( <
                            Grid.Column width = { 8 } >
                            <
                            Header as = 'h2' > Osnovne informacije < /Header> <
                            Table basic = 'very'
                            compact = 'very' >
                            <
                            Table.Body > {
                                this.state.payloadData.general.map((items) => ( <
                                    Table.Row key = { items.title } >
                                    <
                                    Table.Cell textAlign = { "left" } > { items.title } <
                                    /Table.Cell> <
                                    Table.Cell textAlign = { "right" } > { items.value } <
                                    /Table.Cell> <
                                    /Table.Row>
                                ))
                            } <
                            /Table.Body> <
                            /Table> <
                            /Grid.Column>
                        );
                    }

                    if (this.state.payloadData.additional === undefined) {
                        additional = "";
                    } else {
                        additional = ( <
                            Grid.Column width = { 8 } >
                            <
                            Header as = 'h2' > Dodatne informacije < /Header> <
                            Table basic = 'very'
                            compact = 'very' >
                            <
                            Table.Body > {
                                this.state.payloadData.additional.map((items) => ( <
                                    Table.Row key = { items.title } >
                                    <
                                    Table.Cell textAlign = { "left" } > { items.title } <
                                    /Table.Cell> <
                                    Table.Cell textAlign = { "right" } > { items.value } <
                                    /Table.Cell> <
                                    /Table.Row>
                                ))
                            } <
                            /Table.Body> <
                            /Table> <
                            /Grid.Column>
                        );
                    }

                    if (this.state.payloadData.condition === undefined) {
                        condition = "";
                    } else {
                        condition = ( <
                            Grid.Column width = { 16 } >
                            <
                            Header as = 'h2' > Stanje < /Header> <
                            Grid columns = { 4 }
                            doubling > {
                                this.state.payloadData.condition.map((items) => ( <
                                    Grid.Column > { items } < /Grid.Column>
                                ))
                            } <
                            /Grid> <
                            /Grid.Column>
                        );
                    }

                    if (this.state.payloadData.equipment === undefined) {
                        equipment = "";
                    } else {
                        equipment = ( <
                            Grid.Column width = { 8 } >
                            <
                            Header as = 'h2' > Oprema < /Header> <
                            Grid columns = { 2 }
                            doubling > {
                                this.state.payloadData.equipment.map((items) => ( <
                                    Grid.Column > { items } < /Grid.Column>
                                ))
                            } <
                            /Grid> <
                            /Grid.Column>
                        );
                    }

                    if (this.state.payloadData.safety === undefined) {
                        safety = "";
                    } else {
                        safety = ( <
                            Grid.Column width = { 16 } >
                            <
                            Header as = 'h2' > sigurnost < /Header> <
                            Grid columns = { 4 }
                            doubling > {
                                this.state.payloadData.safety.map((items) => ( <
                                    Grid.Column > { items } < /Grid.Column>
                                ))
                            } <
                            /Grid> <
                            /Grid.Column>
                        );
                    }

                    if (this.state.payloadData.description === undefined) {
                        description = "";
                    } else {
                        description = ( <
                            Grid.Column width = { 16 } >
                            <
                            Header as = 'h2' > Opis < /Header> { this.state.payloadData.description } <
                            /Grid.Column>
                        );
                    }

                    if (this.state.payloadData.contact.phones === undefined) {
                        if (this.state.payloadData.contact.cellphones === undefined) {
                            phones = < List floated = 'right' / > ;
                        } else {
                            phones = ( <
                                List floated = 'right' > {
                                    this.state.payloadData.contact.cellphones.map((items) => ( <
                                        List.Item key = { items } >
                                        <
                                        List.Icon name = 'phone' / >
                                        <
                                        List.Content >
                                        <
                                        a href = { `tel:${items}` } > { items } < /a> <
                                        /List.Content> <
                                        /List.Item>
                                    ))
                                }; <
                                /List>
                            );
                        }
                    } else {
                        if (this.state.payloadData.contact.cellphones === undefined) {
                            phones = ( <
                                List floated = 'right' > {
                                    this.state.payloadData.contact.phones.map((items) => ( <
                                        List.Item key = { items } >
                                        <
                                        List.Icon name = 'phone' / >
                                        <
                                        List.Content >
                                        <
                                        a href = { `tel:${items}` } > { items } < /a> <
                                        /List.Content> <
                                        /List.Item>
                                    ))
                                } <
                                /List>
                            );
                        } else {
                            phones = ( <
                                List floated = 'right' > {
                                    this.state.payloadData.contact.phones.map((items) => ( <
                                        List.Item key = { items } >
                                        <
                                        List.Icon name = 'phone' / >
                                        <
                                        List.Content >
                                        <
                                        a href = { `tel:${items}` } > { items } < /a> <
                                        /List.Content> <
                                        /List.Item>
                                    ))
                                } {
                                    this.state.payloadData.contact.cellphones.map((items) => ( <
                                        List.Item key = { items } >
                                        <
                                        List.Icon name = 'phone' / >
                                        <
                                        List.Content >
                                        <
                                        a href = { `tel:${items}` } > { items } < /a> <
                                        /List.Content> <
                                        /List.Item>
                                    ))
                                } <
                                /List>
                            );
                        }
                    }

                    this.setState({
                        title: title,
                        images: images,
                        price: this.state.payloadData.priceInfo.price,
                        additional: additional,
                        general: general,
                        condition: condition,
                        equipment: equipment,
                        safety: safety,
                        description: description,
                        contact: ( <
                            Table basic = 'very'
                            compact = 'very' >
                            <
                            Table.Body >
                            <
                            Table.Row >
                            <
                            Table.Cell textAlign = { "left" } > Naziv < /Table.Cell> <
                            Table.Cell textAlign = { "right" } > { this.state.payloadData.contact.agencyName } <
                            /Table.Cell> <
                            /Table.Row> <
                            Table.Row >
                            <
                            Table.Cell textAlign = { "left" } > Adresa < /Table.Cell> <
                            Table.Cell textAlign = { "right" } > { this.state.payloadData.contact.address } <
                            /Table.Cell> <
                            /Table.Row> <
                            Table.Row >
                            <
                            Table.Cell textAlign = { "left" } > Adresa < /Table.Cell> <
                            Table.Cell textAlign = { "right" } > { this.state.payloadData.contact.address } { " " } { this.state.payloadData.contact.city } <
                            /Table.Cell> <
                            /Table.Row> <
                            Table.Row >
                            <
                            Table.Cell textAlign = { "left" } > Telefon: < /Table.Cell> <
                            Table.Cell textAlign = { "right" } > { phones } < /Table.Cell> <
                            /Table.Row> <
                            /Table.Body> <
                            /Table>
                        )
                    });
                });
        });
    }

    render() {
        return ( <
            Container >
            <
            MetaTags >
            <
            title > { this.state.payloadData.title + " | Auto Plac - DM Valjevo" } <
            /title> <
            meta name = 'description'
            content = { this.state.payloadData.description }
            /> <
            meta property = 'og:title'
            content = { this.state.payloadData.title + " | Auto Plac - DM Valjevo" }
            /> <
            meta property = 'og:image'
            content = { this.state.images[0].original }
            /> <
            /MetaTags> <
            Segment >
            <
            Divider hidden / >
            <
            Divider hidden / >
            <
            Grid container stackable doubling >
            <
            Grid.Row >
            <
            Grid.Column width = { 10 } > { this.state.title } < /Grid.Column> <
            Grid.Column width = { 6 }
            textAlign = { "right" } >
            <
            Label color = 'red'
            size = 'huge'
            icon = 'money bill alternate outline'
            content = { this.state.price }
            /> <
            /Grid.Column> <
            /Grid.Row> <
            Grid.Row >
            <
            Grid.Column width = { 10 } >
            <
            ImageGallery items = { this.state.images }
            autoPlay = { false }
            showThumbnails = { false }
            useBrowserFullscreen = { true }
            onScreenChange = { this._onScreenChange.bind(this) }
            showFullscreenButton = { true }
            lazyLoad = { true }
            /> <
            /Grid.Column> <
            Grid.Column width = { 6 } >
            <
            Header as = 'h2' > Kontakt podaci < /Header> { this.state.contact } { this.state.general } <
            /Grid.Column> <
            /Grid.Row> { this.state.additional } { this.state.equipment } { this.state.condition } { this.state.safety } { this.state.description } <
            /Grid> <
            /Segment> <
            Divider hidden / >
            <
            Divider hidden / >
            <
            Divider hidden / >
            <
            CallUsDetails / >
            <
            /Container>
        );
    }
}

export default VehicleDetails;