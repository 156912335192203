import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./components/main/header";

import Home from "./Home";
import Error from "./Error";
import Vehicles from "./Vehicles";
import Contact from "./Contact";
import AboutUs from "./AboutUs";
import Gallery from "./Gallery";
import VehicleDetails from "./components/details/vehicle-details";

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<div>
					<Header />
					<Switch>
						<Route path="/" component={Home} exact />
						<Route path="/vozila" component={Vehicles} exact />
						<Route
							path="/vozila/:classifiedId/:classifiedName"
							component={VehicleDetails}
						/>
						<Route path="/delovi/:classifiedId" component={VehicleDetails} />
						<Route path="/kontakt" component={Contact} exact />
						<Route path="/o-nama" component={AboutUs} exact />
						<Route path="/galerija" component={Gallery} exact />
						<Route component={Error} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
