import React, { Component } from "react";

class Error extends Component {
	render() {
		return (
			<div>
				<div style={{ "margin-top": "20%" }}>Došlo je do greške 404.</div>
			</div>
		);
	}
}

export default Error;
