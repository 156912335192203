import React, { Component } from "react";
import MetaTags from "react-meta-tags";

import VehicleInfiniteScroll from "./components/vehicle-list/vehicle-infinite-scroll";

class Home extends Component {
	render() {
		return (
			<div>
				<MetaTags>
					<title>Prodaja polovnih automobila | Auto plac - DM Valjevo</title>
					<meta
						name="description"
						content="Pogledajte našu ponudu! DM Valjevo - Auto plac u Valjevu od sada i na internetu. Na našem sajtu čeka Vas veliki izbor kvalitetnih polovnih automobila iz uvoza."
					/>
					<meta
						property="og:title"
						content="Prodaja polovnih automobila | Auto plac - DM Valjevo"
					/>
				</MetaTags>
				<VehicleInfiniteScroll />
			</div>
		);
	}
}

export default Home;
