import React, { Component } from "react";
import { Menu, Image, Container } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import logo from "../../../res/dm-valjevo-logo.svg";

export default class DestkopHeader extends Component {
	state = { activeItem: "" };

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	render() {
		const { activeItem } = this.state;

		return (
			<Menu borderless fixed="top" size="massive">
				<Container>
					<Image as={NavLink} to="/">
						<img
							style={{
								margin: "8px",
								width: "135px",
								height: "45px"
							}}
							src={logo}
							alt="DM Valjevo - Logo"
						/>
					</Image>
					<Menu.Item
						as={NavLink}
						to="/vozila"
						name="vozila"
						active={activeItem === "vozila"}
						onClick={this.handleItemClick}
					>
						Vozila
					</Menu.Item>
					{/* <Menu.Item
						as={NavLink}
						to="/galerija"
						name="galerija"
						active={activeItem === "galerija"}
						onClick={this.handleItemClick}
					>
						Galerija
					</Menu.Item> */}
					<Menu.Item
						as={NavLink}
						to="/kontakt"
						name="kontakt"
						active={activeItem === "kontakt"}
						onClick={this.handleItemClick}
					>
						Kontakt
					</Menu.Item>
					<Menu.Item
						as={NavLink}
						to="/o-nama"
						name="o-nama"
						active={activeItem === "o-nama"}
						onClick={this.handleItemClick}
					>
						O nama
					</Menu.Item>
				</Container>
			</Menu>
		);
	}
}
