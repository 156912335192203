import React, { Component } from "react";
import { Header, Icon, Container, Grid } from "semantic-ui-react";

class ReviewItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reviewTitle: props.reviewTitle,
			reveiwText: props.reveiwText
		};
	}
	render() {
		return (
			<Container text>
				<Grid centered>
					<Grid.Row>
						<Header as="h3" textAlign="center">
							{this.state.reviewTitle}
						</Header>
					</Grid.Row>
					<Grid.Row>
						<Icon name="star" color="yellow" />
						<Icon name="star" color="yellow" />
						<Icon name="star" color="yellow" />
						<Icon name="star" color="yellow" />
						<Icon name="star" color="yellow" />
					</Grid.Row>
					<p centered>{this.state.reveiwText}</p>
				</Grid>
			</Container>
		);
	}
}

export default ReviewItem;
