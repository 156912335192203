import React, { Component } from "react";
import { Menu, Icon, Responsive } from "semantic-ui-react";

class CallUsDetails extends Component {
	render() {
		return (
			<Responsive
				as={Menu}
				maxWidth={500}
				borderless
				fluid
				fixed="bottom"
				size="massive"
				widths={1}
				color="red"
				inverted
			>
				<Menu.Item fluid as="a" href="tel:0600141010">
					<Icon name="phone" />
					Pozovite nas!
				</Menu.Item>
			</Responsive>
		);
	}
}

export default CallUsDetails;
