import React, { Component } from "react";
import { Container, Header, Segment, Divider } from "semantic-ui-react";
import background from "./res/background-dm-valjevo.png";
import MetaTags from "react-meta-tags";

class AboutUs extends Component {
	state = {
		documentHeight: 0
	};

	componentDidMount() {
		this.setState({ documentHeight: document.documentElement.offsetHeight });
	}

	render() {
		return (
			<div
				style={{
					backgroundImage: `url("${background}")`,
					height: this.state.documentHeight - 61,
					backgroundSize: "cover",
					backgroundAttachment: "fixed"
				}}
			>
				<MetaTags>
					<title>
						O nama | Auto plac - DM Valjevo | Prodaja polovnih automobila
					</title>
					<meta
						name="description"
						content="DM Valjevo - Auto plac u Valjevu od sada i na internetu. Na našem sajtu čeka Vas veliki izbor kvalitetnih, dobro proverenih polovnih automobila iz uvoza."
					/>
					<meta
						property="og:title"
						content="Auto plac - DM Valjevo | Prodaja polovnih automobila"
					/>
				</MetaTags>
				<Divider hidden />
				<Divider hidden />
				<Container text>
					<Segment raised>
						<Header as="h1" textAlign="center">
							O nama
						</Header>

						<p style={{ fontSize: "18px" }}>
							Auto plac - DM Valjevo DM Valjevo (DM car export import d.o.o.) je
							kompanija koja se bavi uvozom i prodajom izuzetno očuvanih i
							dobrih automobila i teretnih vozila iz Belgije, Nemačke i
							delimično sa severa Italije.
						</p>
						<p style={{ fontSize: "18px" }}>
							Dugogodišnjim iskustvom stekli smo partnere i naša nabavka vozila
							bazirana je na profesionalnoj saradnji i poverenju. Upravo to
							povrenje želimo da prenesemo na vas prilikom kupovine naših
							vozila.
						</p>
						<p style={{ fontSize: "18px" }}>
							Svoje znanje i dvadesetogodišnje iskustvo u nabavci i prodaji
							automobila, usmerili smo isključivo na zadovoljne kupce. Naše
							usluge razvijamo u pravcu olakšavanja dobre kupovine bez rizika
							koji su učestali na našem tržištu. Kvalitetnim vozilima,
							profesionalizmom i konkurentnim cenama izgradili smo prijateljski
							brend okrenut kupcima i partnerima. Otvoreni smo za sve vaše
							predloge i saradnju.
						</p>
						<p style={{ fontSize: "18px" }}>
							Na našem prodajnom mestu će vas sačekati ljubazno i iskusno
							osoblje koje će vam pomoći da definišete pravi izbor i zadovoljite
							vaše potrebe. Preporučićemo najbolje vozilo za vas, koje će vas
							dobro služiti i učiniti vas našim partnerom. Za našu kompaniju
							partnerstvo sa kupcem započinje kupovinom vozila kod nas.
						</p>
						<p style={{ fontSize: "18px" }}>
							Prilikom kupovine našeg vozila moguća je probna vožnja kako biste
							se uverili u kvalitet i ispravnost vozila. Kod nas možete uraditi
							tehnički pregled i registraciju vozila. Prodajno mesto našeg auto
							placa nalazi se u Valjevu.
						</p>
						<p style={{ fontSize: "18px" }}>
							Izlaz iz Valjeva, magistralni put Valjevo-Beograd, Popučke BB kod
							NIS-ove benzinske pumpe.
						</p>
						<p style={{ fontSize: "18px" }}>
							Srdačno vas očekujemo. Vaš{" "}
							<a href="https://www.polovniautomobili.com/dmvaljevo">
								DM Valjevo
							</a>
							.
						</p>
					</Segment>
				</Container>
				<Divider hidden />
			</div>
		);
	}
}

export default AboutUs;
