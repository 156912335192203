import React, { Component } from "react";
import { Header, Table, Grid } from "semantic-ui-react";

class WorkTimeSegment extends Component {
	constructor(props) {
		super(props);
		this.state = { customTitle: props.customTitle };
	}
	render() {
		return (
			<Grid centered>
				<Header as="h2" textAlign="center">
					{this.state.customTitle}
				</Header>
				<Table fixed basic="very">
					<Table.Body>
						<Table.Row>
							<Table.Cell>Ponedeljak</Table.Cell>
							<Table.Cell>08:00 - 18:00</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Utorak</Table.Cell>
							<Table.Cell>08:00 - 18:00</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Sreda</Table.Cell>
							<Table.Cell>08:00 - 18:00</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Četvrtak</Table.Cell>
							<Table.Cell>08:00 - 18:00</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Petak</Table.Cell>
							<Table.Cell>08:00 - 18:00</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Subota</Table.Cell>
							<Table.Cell>08:00 - 18:00</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Nedelja</Table.Cell>
							<Table.Cell>08:00 - 17:00</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Grid>
		);
	}
}

export default WorkTimeSegment;
