import React, { Component } from "react";
import { Item, Label, Grid } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

class VehicleItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classified: props.classified
		};
	}

	render() {
		var classified = this.state.classified;

		var { title, url, subtitle, image, price, discountedFrom, city } = "";

		var { firstColumn, secondColumn } = [];

		if (classified.title === undefined) {
			title = <Item.Header content={"Greška pri učitavanju oglasa"} />;
			url = "undefined";
		} else {
			url = `/vozila/${
				classified.AdID
			}/${classified.title.toLowerCase().replace(/ /gi, "-")}`;
			title = <Item.Header as={NavLink} to={url} content={classified.title} />;
		}

		if (classified.adImages === undefined) {
			image = (
				<Item.Image
					src="https://react.semantic-ui.com/images/wireframe/image.png"
					alt={classified.title}
					size="medium"
				/>
			);
		} else {
			image = (
				<Item.Image
					as={NavLink}
					to={url}
					src={`${classified.adImages.basePath}${
						classified.adImages.images[0]
					}-800x600.jpg`}
					alt={classified.title}
					size="medium"
				/>
			);
		}

		if (classified.subtitle === undefined) {
			subtitle = "";
		} else {
			subtitle = (
				<Item.Description>
					<Item.Meta>{classified.subtitle}</Item.Meta>
				</Item.Description>
			);
		}

		if (classified.firstColumn === undefined) {
			firstColumn = [];
		} else {
			firstColumn = (
				<Grid.Column>
					{classified.firstColumn.map(feature => (
						<Item.Description key={feature} content={feature} />
					))}
				</Grid.Column>
			);
		}

		if (classified.secondColumn === undefined) {
			secondColumn = [];
		} else {
			secondColumn = (
				<Grid.Column>
					{classified.secondColumn.map(feature => (
						<Item.Description key={feature} content={feature} />
					))}
				</Grid.Column>
			);
		}

		if (classified.discountedFrom === undefined) {
			discountedFrom = "";
		} else {
			discountedFrom = (
				<Label
					size="large"
					icon="money bill alternate outline"
					content={classified.discountedFrom}
				/>
			);
		}

		if (classified.price === undefined) {
			price = "";
		} else {
			price = (
				<Label
					color="red"
					size="large"
					icon="money bill alternate outline"
					content={classified.price}
				/>
			);
		}

		if (classified.city === undefined) {
			city = "";
		} else {
			city = (
				<Label
					size="large"
					icon="map marker alternate"
					content={classified.city}
				/>
			);
		}

		return (
			<Item key={classified.AdID}>
				{image}
				<Item.Content>
					{title}
					{subtitle}
					<Item.Content verticalAlign="middle">
						<Grid columns={2}>
							{firstColumn}
							{secondColumn}
						</Grid>
					</Item.Content>
					<Item.Extra>
						{discountedFrom}
						{price}
						{city}
					</Item.Extra>
				</Item.Content>
			</Item>
		);
	}
}

export default VehicleItem;
