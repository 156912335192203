import React, { Component } from "react";
import { Header, Button, Grid, Divider } from "semantic-ui-react";

class MapSegment extends Component {
	constructor(props) {
		super(props);
		this.state = { customTitle: props.customTitle };
	}
	render() {
		return (
			<Grid centered>
				<Header as="h2" textAlign="center">
					{this.state.customTitle}
				</Header>
				<iframe
					title="mapa"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23516.968091289316!2d19.941893226641252!3d44.29071062223143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4759f1f7a5640a25%3A0xc681f94c25f7c161!2sAuto+plac+DM+VALjEVO!5e0!3m2!1sen!2srs!4v1540853310852"
					width="100%"
					height="315px"
					frameBorder="0"
					style={{ border: "0" }}
					allowFullScreen
				/>
				<Divider fitted />
				<Button.Group fluid>
					<Button
						basic
						color="red"
						as="a"
						href="https://www.google.com/maps/dir/?api=1&destination=Auto+plac+DM+VALjEVO&travelmode=car"
						target="_blank"
						icon="map"
						content="Prikaži putanju"
					/>

					<Button
						basic
						color="red"
						as="a"
						href="https://www.google.com/maps/search/?api=1&query=Auto+plac+DM+VALjEVO"
						target="_blank"
						icon="map marker alternate"
						content="Lokacija"
					/>
				</Button.Group>
			</Grid>
		);
	}
}

export default MapSegment;
