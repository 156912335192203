import React, { Component } from "react";
import {
	Item,
	Container,
	Loader,
	Header,
	Icon,
	Input,
	Segment,
	Divider
} from "semantic-ui-react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import AdItem from "./vehicle-item";

class VehicleInfiniteScroll extends Component {
	constructor(props) {
		super(props);
		this.getMoreClassifieds = this.getMoreClassifieds.bind(this);
	}

	state = {
		classifieds: [],
		results: 0,
		page: 0,
		loading: false,
		searchTerm: ""
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	_handleKeyPress = (e) => {
		if (e.key === "Enter") {
			this.searchAds();
		}
	};

	componentDidMount() {
		this.getMoreClassifieds();
	}

	getMoreClassifieds() {
		this.setState({ page: this.state.page + 1 }, () => {
			axios
				.get(
					`https://api.polovniautomobili.com/json/v4/getDealerAds/785?searchTerm=${
						this.state.searchTerm
					}&page=${this.state.page}`
				)
				.then((res) => {
					this.setState({
						classifieds: [
							...this.state.classifieds,
							...res.data.payloadData.classifieds
						]
					});
					this.setState({
						results: res.data.payloadData.totalResults,
						load: this.hasMoreCheck(
							this.state.page,
							Math.round(res.data.payloadData.totalResults / 25)
						),
						loading: false
					});
				});
		});
	}

	searchAds = () => {
		this.setState({ page: 1 }, () => {
			axios
				.get(
					`https://api.polovniautomobili.com/json/v4/getDealerAds/785?searchTerm=${
						this.state.searchTerm
					}`
				)
				.then((res) => {
					this.setState({
						classifieds: res.data.payloadData.classifieds
					});
					this.setState({
						results: res.data.payloadData.totalResults,
						load: this.hasMoreCheck(
							1,
							Math.round(res.data.payloadData.totalResults / 25)
						),
						loading: false
					});
				});
		});
	};

	hasMoreCheck(currentPage, totalPages) {
		if (currentPage > totalPages) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		return (
			<Container text>
				<Segment>
					<Divider hidden />
					<Divider hidden />
					<Input
						fluid
						name="searchTerm"
						value={this.state.searchTerm}
						onChange={this.handleChange}
						placeholder="Pretraži..."
						icon={{
							name: "search",
							circular: true,
							link: true,
							onClick: this.searchAds
						}}
						onKeyPress={this._handleKeyPress}
					/>
					<InfiniteScroll
						style={{ padding: "20px" }}
						dataLength={this.state.classifieds.length}
						next={this.getMoreClassifieds}
						hasMore={this.state.load}
						loader={<Loader active inline="centered" />}
						endMessage={
							<Header icon as="h2" textAlign="center">
								<Icon size="huge" name="car" />
								<Header.Content>
									Učitani su svi oglasi!
									<Header.Subheader>
										Trenutno ima {this.state.results} oglasa
									</Header.Subheader>
								</Header.Content>
							</Header>
						}
					>
						<Item.Group divided>
							{this.state.classifieds.map((classified) => (
								<AdItem key={classified.AdID} classified={classified} />
							))}
						</Item.Group>
					</InfiniteScroll>
				</Segment>
			</Container>
		);
	}
}

export default VehicleInfiniteScroll;
