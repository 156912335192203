import React, { Component } from "react";
import { Segment, Responsive } from "semantic-ui-react";
import MobileHeader from "./mobile/mobile-header";
import DestkopHeader from "./desktop/desktop-header";

export default class Header extends Component {
	render() {
		return (
			<Segment.Group raised stacked style={{ marginBottom: "31px" }}>
				<Responsive as={Segment} maxWidth={768}>
					<MobileHeader />
				</Responsive>
				<Responsive as={Segment} minWidth={769}>
					<DestkopHeader />
				</Responsive>
			</Segment.Group>
		);
	}
}
