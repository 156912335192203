import React, { Component } from "react";
import { Container, Header, Grid, Divider, Button } from "semantic-ui-react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import hero from "./res/hero.jpg";
import { NavLink } from "react-router-dom";
import BrandsSegment from "./components/content/brands-segment";
import ReviewItem from "./components/content/reveiw-item";
import MetaTags from "react-meta-tags";

function ReviewList(props) {
	const reviews = props.reviews;

	const reviewsList = reviews.map((review) => (
		<Slide>
			<Divider hidden />
			<ReviewItem reviewTitle={review.reviewer} reveiwText={review.review} />
		</Slide>
	));
	return <Slider>{reviewsList}</Slider>;
}

class Home extends Component {
	state = {
		reviews: [
			{
				reviewer: "Slobodan Ilic",
				review:
					"Dobar je plac, veliki izbor automobila, nalazi se na lepom mestu ali nekako ima prejake cene. Ljubazni su radnici."
			},
			{
				reviewer: "Vladan Kovacevic",
				review: "Odlična ponuda,proverena usluga!"
			},
			{
				reviewer: "Zoran Tomic",
				review:
					"Veliki izbor Automobila... Odlična saradnja 👍 Sve preporuke...10+"
			},
			{
				reviewer: "Milan Djokic",
				review:
					"Veoma sam zadovoljan izborom automobila.Sve preporuke za ovaj auto plac u Valjevu!"
			},
			{
				reviewer: "Aleksandra Damjanovic",
				review: "Prodaja polovnih automobila. Ljubazno i efikasno."
			},
			{
				reviewer: "Petar Milosevic",
				review:
					"Sve pohvale za vlasnika.Usluga,komunikacija na naj visem nivou.Samo tako nastavite,naj bolji auto plac na kojem sam bio."
			},
			{
				reviewer: "Caslav Lukic",
				review:
					"Nemam dilemu gde da kupim vozilo za sebe i svoje... DM - No 1.. Hvala na svemu"
			},
			{
				reviewer: "Milovan Petrovic",
				review:
					"zuzetno dobra ponuda vozila. Kupio sam  vozilo I sve sto mi je bilo receno,sve je bilo tacno . Ja Sam prezadovoljan. Preporuka stoji!"
			},
			{
				reviewer: "Zdravko Saric",
				review:
					"Uzeo sam corsu i veoma sam zadovoljan ,gazda je posten i sve pohvale dobri automobili .zdravko ub"
			},
			{
				reviewer: "Milos Simsic",
				review: "Veliki pozdrav veoma ste profesionalni 👍"
			},
			{
				reviewer: "Igor Arsic",
				review:
					"Kupio sam audi q7 i veoma sam zadovoljan ,povoljne cene a usluga na nivou.Svaka čast Draganu."
			},
			{
				reviewer: "Aca Kostic",
				review: "Moje preporuke za ovaj auto plac,skroz korektni ljudi"
			},
			{
				reviewer: "Dea J.",
				review: "Plac broj jedan u Srbiji"
			},
			{
				reviewer: "Goran Godjevac",
				review: "Najbolji auto plac u srbiji a možda i u regionu"
			},
			{
				reviewer: "Nebojsa Maksimovski",
				review:
					"Odlican izbor odlicnih automobila... Ja sam i vise nego zadovoljan"
			},
			{
				reviewer: "Boban  Puresevic",
				review:
					"Jako ozbiljna auto kuca.Samo tako nastavite.Pohvale i pozdravi iz  Kikinde"
			},
			{
				reviewer: "Milos Vasic",
				review: "Super ste pozz"
			},
			{
				reviewer: "Milos Nestorovic",
				review: "Izuzetna vozila, fina i korektna saradnja. Za svaku preporuku!"
			},
			{
				reviewer: "Milan Bojicic",
				review: "Odgovorni.i posteni"
			}
		]
	};

	render() {
		return (
			<div>
				<MetaTags>
					<title>
						Auto plac - DM Valjevo | Prodaja i uvoz polovnih automobila
					</title>
					<meta
						name="description"
						content="DM Valjevo - Auto plac u Valjevu od sada i na internetu. Na našem sajtu čeka Vas veliki izbor kvalitetnih, dobro proverenih polovnih automobila iz uvoza."
					/>
					<meta
						property="og:title"
						content="Auto plac - DM Valjevo | Prodaja polovnih automobila"
					/>
				</MetaTags>
				<div
					style={{
						maxHeight: "400px",
						backgroundImage: "url(" + hero + ")",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center",
						paddingTop: 160,
						textAlign: "center",
						paddingBottom: 160
					}}
				>
					<Header
						as="h1"
						textAlign="center"
						size="huge"
						style={{ color: "#fff", textShadow: "1px 1px 1px black" }}
					>
						AUTO PLAC - DM VALJEVO
					</Header>
					<p
						style={{
							fontSize: "18px",
							color: "#fff",
							textAlign: "center",
							textTransform: "uppercase",
							textShadow: "1px 1px 1px black"
						}}
					>
						Pronađite savršen automobil za sebe!
					</p>
					<Button
						size="massive"
						as={NavLink}
						to="/vozila"
						color="red"
						icon="car"
						content="Pogledajte ponudu"
					/>
				</div>

				<Container>
					<Divider hidden />
					<Divider horizontal>
						<Header as="h2">Ko smo mi?</Header>
					</Divider>
					<Grid columns={2} stackable>
						<Grid.Row>
							<Grid.Column>
								<p style={{ fontSize: "18px" }}>
									Auto plac - DM Valjevo (DM car export import d.o.o.) je
									kompanija koja se bavi uvozom i prodajom izuzetno očuvanih i
									dobrih automobila i teretnih vozila iz Belgije, Nemačke i
									delimično sa severa Italije.
								</p>
							</Grid.Column>
							<Grid.Column>
								<p style={{ fontSize: "18px" }}>
									Dugogodišnjim iskustvom stekli smo partnere i naša nabavka
									vozila bazirana je na profesionalnoj saradnji i poverenju.
									Upravo to povrenje želimo da prenesemo na vas prilikom
									kupovine naših vozila.
								</p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Divider hidden />
				</Container>
				<Container>
					<Divider horizontal>
						<Header as="h2">Automobili koje uvozimo</Header>
					</Divider>
					<Container content>
						<BrandsSegment />
					</Container>
					<Divider hidden />
				</Container>
				<Container>
					<Divider horizontal>
						<Header as="h2">Utisci naših kupaca</Header>
					</Divider>
					<Container text>
						<CarouselProvider
							totalSlides={this.state.reviews.length}
							naturalSlideWidth={1}
							naturalSlideHeight={1}
							playDirection="forward"
							isPlaying={true}
						>
							<ReviewList reviews={this.state.reviews} />
						</CarouselProvider>
					</Container>
				</Container>
			</div>
		);
	}
}

export default Home;
